import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useHistory } from 'react-router-dom';
import Page from 'components/page/page';
import Panel from 'components/panel/panel';
import Button from 'components/button/button';
import { css } from 'emotion';
import IphoneMockupImage from 'images/iphone-mockup.png';
import RV2GIphoneMockupImage from 'images/rv2g-iphone-mockup.png';
import AppStoreBadge from 'images/app-store-badge.svg';
import GooglePlayBadge from 'images/google-play-badge.svg';
import {
  QR2GSchoolsLogin
} from 'utils/api';


const QR2GSchoolsWelcomePage = () => {
  const history = useHistory();
  //console.log("in qr2gschools welcome page")
  return (
    <Panel>
      <h1>Welcome to QR2GSchools</h1>
      <div
        className={css`
            display: flex;
            @media (max-width: 650px) {
              flex-direction: column;
            }
          `}
      >
        <div
          className={css`
              flex: 1;
              padding: 1rem 0;
              @media (max-width: 650px) {
                display: flex;
                justify-content: center;
              }
            `}
        >
          <img
            src={IphoneMockupImage}
            alt="QR2GSchools App"
            className={css`
                width: 100%;
                height: auto;
                @media (max-width: 650px) {
                  width: auto;
                  height: 15rem;
                }
              `}
          />
        </div>
        <div
          className={css`
              flex: 2;
              margin-left: 2rem;

              @media (max-width: 650px) {
                margin-left: 0;
              }
            `}
        >
          <p>
            The QR2GSchools Program allows you to give back to a local
            schools with every QR Code scan of your favorite brands. As you shop in your local Converse Store,
            you can scan the QR code at the location of your favorite brands OR make a purchase and receive a sticker and start giving back with
            every visit!
          </p>
          <div
            className={css`
                display: flex;
                justify-content: center;
              `}
          >
            <a href="https://apps.apple.com/us/app/reuse-2-give/id1534024782">
              <img
                src={AppStoreBadge}
                alt="Apple App Store"
                className={css`
                    height: 3rem;
                    margin: 0 0.5rem;
                    @media (max-width: 450px) {
                      height: 2.5rem;
                    }
                  `}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.qr2gschools">
              <img
                src={GooglePlayBadge}
                alt="Google Play Store"
                className={css`
                    height: 3rem;
                    margin: 0 0.5rem;
                    @media (max-width: 450px) {
                      height: 2.5rem;
                    }
                  `}
              />
            </a>
          </div>
          <p>
            If you have already registered, Sign In to your account to direct
            your donations and see your community impact!
          </p>
          <div
            className={css`
                display: flex;
                justify-content: center;
              `}
          >

            <Button
              size="large"
              onClick={() => history.push('/login')}
            >
              Sign In
            </Button>
          </div>
        </div>
      </div>
    </Panel >
  );
}

const Rv2gWelcomePage = () => {
  const { loginWithRedirect } = useAuth0();
  // console.log("in rv2g welcome page")
  return (

    <Panel>
      <h1>Welcome to QR 2Give</h1>
      <div
        className={css`
            display: flex;
            @media (max-width: 650px) {
              flex-direction: column;
            }
          `}
      >
        <div
          className={css`
              flex: 1;
              padding: 1rem 0;
              @media (max-width: 650px) {
                display: flex;
                justify-content: center;
              }
            `}
        >
          <img
            src={RV2GIphoneMockupImage}
            alt="QR 2Give App"
            className={css`
                width: 100%;
                height: auto;
                @media (max-width: 650px) {
                  width: auto;
                  height: 15rem;
                }
              `}
          />
        </div>
        <div
          className={css`
              flex: 2;
              margin-left: 2rem;

              @media (max-width: 650px) {
                margin-left: 0;
              }
            `}
        >
          <p>
            The QR 2Give Program allows you to give back to a local
            non-profit with every QR Code scan of your favorite brands. As you shop in your local supermarket, you can scan the QR code at the location of your favorite brands and start giving back with
            every visit!
          </p>
          <div
            className={css`
                display: flex;
                justify-content: center;
              `}
          >
            <a href="https://apps.apple.com/us/app/reuse-2-give/id1534024782">
              <img
                src={AppStoreBadge}
                alt="Apple App Store"
                className={css`
                    height: 3rem;
                    margin: 0 0.5rem;
                    @media (max-width: 450px) {
                      height: 2.5rem;
                    }
                  `}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.revisit2give">
              <img
                src={GooglePlayBadge}
                alt="Google Play Store"
                className={css`
                    height: 3rem;
                    margin: 0 0.5rem;
                    @media (max-width: 450px) {
                      height: 2.5rem;
                    }
                  `}
              />
            </a>
          </div>
          <p>
            If you have already registered, Sign In to your account to direct
            your donations and see your community impact!
          </p>
          <div
            className={css`
                display: flex;
                justify-content: center;
              `}
          >
            <Button
              size="large"
              onClick={() => loginWithRedirect({ prompt: 'login' })}
            >
              Sign In
            </Button>
          </div>
        </div>
      </div>
    </Panel>
  );
}


function WelcomePage({ appID }) {
  return (
    <Page>
      {appID == 4 ? <QR2GSchoolsWelcomePage /> :
        <Rv2gWelcomePage />}
    </Page>
  )

}

export default WelcomePage;
