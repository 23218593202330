import { useState, useEffect } from 'react';

const isClient = typeof window === 'object';

const getSize = () => {
  let screenSize;
  if (window.innerWidth >= 950) {
    screenSize = 'large';
  } else if (window.innerWidth >= 600) {
    screenSize = 'medium';
  } else {
    screenSize = 'small';
  }

  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
    screenSize,
  };
};

// Hook
export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
