import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import { useTheme } from 'emotion-theming';
import { PanelTile } from 'components/panel/panel';

const StatsTileIcon = ({ icon, alt = '' }) => (
  <img
    className={css`
      width: 4rem;
      @media (max-width: 750px) {
        margin-bottom: 0.5rem;
      }
    `}
    src={icon}
    alt={alt}
  />
);

const StatsTileContent = ({ children }) => (
  <section
    className={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 1rem;
    `}
  >
    {children}
  </section>
);

const StatsTileValue = ({ children }) => (
  <h2
    className={css`
      font-size: 2.5rem;
      margin: 0.5rem 0;
    `}
  >
    {children}
  </h2>
);

const StatsTileLabel = ({ children }) => {
  const { colors } = useTheme();
  return (
    <section
      className={css`
        font-size: 1.125rem;
        text-align: center;
        white-space: pre-wrap;
        line-height: 1.35;
        margin-bottom: 0.5rem;
        color: ${colors.lightBlack};
      `}
    >
      {children}
    </section>
  );
};

const StatsTileSubLabel = ({ children }) => {
  const { colors } = useTheme();
  return (
    <em
      className={css`
        display: block;
        font-size: 0.875rem;
        text-align: center;
        color: ${colors.grayedOut};
        padding-bottom: 1rem;
        margin-top: -0.75rem;
      `}
    >
      {children}
    </em>
  );
};

const StatsTile = ({ children, link, subLabel }) => {
  const { colors } = useTheme();

  const statsTileLinkStyles = css`
    text-decoration: none;
    transition: 80ms all;
    &:hover {
      outline: 2px solid ${colors.lightBlue};
    }
  `;

  const statsTileContainerStyles = css`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem 0;
    @media (max-width: 750px) {
      flex-direction: column;
    }
    @media (max-width: 650px) {
      flex-direction: row;
    }
  `;

  return link ? (
    <Link className={statsTileLinkStyles} to={link}>
      <PanelTile>
        <section className={statsTileContainerStyles}>{children}</section>
        {subLabel && <StatsTileSubLabel>{subLabel}</StatsTileSubLabel>}
      </PanelTile>
    </Link>
  ) : (
    <PanelTile>
      <section className={statsTileContainerStyles}>{children}</section>
      {subLabel && <StatsTileSubLabel>{subLabel}</StatsTileSubLabel>}
    </PanelTile>
  );
};

export {
  StatsTile as default,
  StatsTileIcon,
  StatsTileContent,
  StatsTileValue,
  StatsTileLabel,
};
