import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import Page from 'components/page/page';
import CauseTable from 'components/cause-table/cause-table';
import { useDonations } from 'contexts/donation-context';
import { useAuth } from 'contexts/auth-context';
import Panel from 'components/panel/panel';
import ConfirmationModal from 'components/confirmation-modal/confirmation-modal';
import LastNonProfit from 'components/last-non-profit/last-non-profit';
import { PanelLoader } from 'components/loader/loader';
import { formatMoney } from 'utils/money';
import { useTheme } from 'emotion-theming';
import useScroll from 'hooks/use-scroll';
import { css } from 'emotion';
import './redeem-page.scss';

const RedeemHeaderImage = ({ src, alt = '' }) => {
  return (
    <div
      className={css`
        display: inline-block;
        height: 75%;
      `}
    >
      <img
        src={src}
        alt={alt}
        className={css`
          flex: none;
          display: block;
          max-width: 100%;
          transition: 120ms all;
          height: 100%;
          margin-right: 1.5rem;
          @media (max-width: 850px) {
            margin-right: 1rem;
          }
        `}
      />
    </div>
  );
};

const RedeemHeaderContainer = ({ children }) => (
  <div
    className={css`
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `}
  >
    {children}
  </div>
);

const RedeemHeader = ({ children, isSmall = false, showHeader }) => {
  const { general } = useTheme();

  return (
    <header
      className={css`
        position: fixed;
        width: 100%;
        height: ${isSmall ? '3rem' : '3rem'};
        background: #fff;
        left: 0;
        marginTop: 2rem;
        box-shadow: ${general.headerBoxShadow};
        display: flex;
        justify-content: center;
        align-items: stretch;
        transition: 120ms all;
        z-index: 1000;
        @media (max-width: 850px) {
          height: ${isSmall ? '5rem' : '5rem'};
          top: ${showHeader ? '3rem' : '3rem'};
        }
      `}
    >
      <section
        className={css`
          max-width: 52rem;
          width: 100%;
          padding: 0 1.25rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (max-width: 850px) {
            padding: 0 0rem;
          }
        `}
      >
        {children}
      </section>
    </header>
  );
};

function RedeemPage() {
  const [isHeaderSmall, setHeaderSmall] = useState(false);
  const { user, appID, showHeader } = useAuth();
  let headerName = 'Non-Profits'

  if (appID == 4) {
    headerName = 'Schools'
  }
  useScroll({
    onScroll: (scroll) => {
      setHeaderSmall(scroll.y > 8);
    },
  });

  const { redeemID } = useParams();
  const { donationList, donationLoadingState, donationLoadingStates } =
    useDonations();

  const { colors } = useTheme();
  const [selectedCause, setSelectedCause] = useState(null);
  const [accountID, sponsorID] = redeemID.split('-').map((id) => Number(id));
  const donationObj = donationList.find((item) => {
    if (appID == 4) {
      return item.StoreAccount.AccountID === accountID && item.RevisitTypeID === sponsorID
    } else {
      return item.StoreAccount.AccountID === accountID;
    }
  });



  if (!donationList || donationLoadingState !== donationLoadingStates.LOADED) {
    return (
      <Page className="redeem-page">
        <PanelLoader />
      </Page>
    );
  }

  if (donationList && !donationObj) {
    return <Redirect to="/redeem" />;
  }

  //console.log("in donation page - user:user", donationObj.TotalDonations, "donationObj:", donationObj)

  return (
    <Page className="redeem-page">
      <RedeemHeader isSmall={isHeaderSmall} showHeader={showHeader}>
        <RedeemHeaderContainer>
          {appID == 4 ? <RedeemHeaderImage
            src={donationObj.StoreAccount.AccountLogo.Original}
            alt={donationObj.StoreAccount.AccountName}
          /> : <RedeemHeaderImage
            src={donationObj.StoreAccount.AccountLogo.Small}
            alt={donationObj.StoreAccount.AccountName}
          />}
        </RedeemHeaderContainer>
        <span
          className={css`
            display: inline-block;
            color: ${colors.lightGreen};
            transition: 120ms all;
            font-size: ${isHeaderSmall ? '2rem' : '2.5rem'};
            font-weight: bold;
            @media (max-width: 850px) {
              font-size: ${isHeaderSmall ? '1.75rem' : '2.25rem'};
            }
            @media (max-width: 500px) {
              font-size: 1.25rem
            }
          `}
        >
          {formatMoney(donationObj.TotalDonations)}
        </span>
      </RedeemHeader>
      <Panel
        className={css`
          margin-top: 6rem;
          @media (max-width: 850px) {
            margin-top: 5rem;
          }
          @media (max-width: 500px) {
            margin-top: 4.5rem;
          }
        `}
      >
        <h1>Direct your Donation</h1>
        {appID == 4 ? <p>
          These donations are proudly provided by{' '}
          <strong>{donationObj.StoreAccount.AccountName}</strong> in support
          of local {headerName}. {donationObj.StoreAccount.AccountName} has
          pledged $50,000 in donations. Learn more about{' '}
          {donationObj.StoreAccount.AccountName} at{' '}
          <a
            href={`${donationObj.StoreAccount.AccountWebsite}?ref=qr2gschools`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {donationObj.StoreAccount.AccountName}
          </a>
          .
        </p> : <p>
          These donations are proudly provided by{' '}
          <strong>
            {donationObj.Sponsors.map((sponsor) => (
              <strong key={donationObj.Sponsors.indexOf(sponsor)}>
                {sponsor.AccountName}, {''}
              </strong>
            ))}
          </strong>{' '}
          in support of local {headerName}{' '}
          {donationObj.Sponsors.map((sponsor) => (
            <strong key={donationObj.Sponsors.indexOf(sponsor)}>
              {sponsor.AccountName}, {''}
            </strong>
          ))}{' '}
          has pledged $250,000 in donations to local non-profits for 1 million
          sponsor visits. Learn more about{' '}
          {donationObj.SponsorAccount.AccountName} at{' '}
          <a
            href={`${donationObj.SponsorAccount.AccountWebsite}?ref=qr2give`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {donationObj.SponsorAccount.AccountWebsite}
          </a>
          .
        </p>}
      </Panel>

      {donationObj?.LastCause?.CompanyID > 0 && (
        <Panel>
          <LastNonProfit
            nonProfit={donationObj.LastCause}
            setSelectedCause={setSelectedCause}
          />
        </Panel>
      )}
      <CauseTable
        accountUID={donationObj.StoreAccount.AccountUUID}
        userLatitude={user.Latitude}
        userLongitude={user.Longitude}
        userZipCode={user.ZipCode}
        causeView={donationObj.CauseView}
        setSelectedCause={setSelectedCause}
      />
      <ConfirmationModal
        sponsors={appID == 4 ? donationObj?.StoreAccount : donationObj?.Sponsors}
        selectedCause={selectedCause}
        hidden={!selectedCause}
        closeModal={() => setSelectedCause(null)}
        amount={formatMoney(donationObj.TotalDonations)}
        donationObj={donationObj}
      />
    </Page>
  );
}

export default RedeemPage;
