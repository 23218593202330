import React, { useEffect, useRef, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import qs from 'qs';
import 'app.scss';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import RedeemPage from 'pages/redeem-page/redeem-page';
import LoginPage from 'pages/login-page/login-page';
import HomePage from 'pages/home-page/home-page';
import WelcomePage from 'pages/welcome-page/welcome-page';
import DonationHistoryPage from 'pages/donation-history-page/donation-history-page';
import useScrollToTop from 'hooks/use-scroll-to-top';
import { useAuth } from 'contexts/auth-context';
import { submitR2gPageView } from 'utils/api';

function App() {
  useScrollToTop();

  const { isLoggedIn, user, appID, setAppID, showHeader } = useAuth();
  const { pathname } = useLocation();

  const UserID = user.id;



  useEffect(() => {
    if (UserID) {
      let AccountID = 0;
      let SponsorID = 0;
      if (pathname.includes('redeem')) {
        const pathnameArr = pathname.split('/').filter((comp) => comp !== '');
        const redeemID = pathnameArr[pathnameArr.length - 1];
        [AccountID, SponsorID] = redeemID.split('-').map((id) => Number(id));
      }

      window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
        page_path: window.location.pathname,
      });
    }
  }, [UserID, pathname]);
  return (
    <div className="App">
      <Header showHeader={showHeader} />
      {
        isLoggedIn ? (
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/donation-history">
              <DonationHistoryPage />
            </Route>
            <Route path="/redeem/:redeemID" exact>
              <RedeemPage />
            </Route>
            <Route path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path="/" exact>
              <WelcomePage appID={appID} />
            </Route>
            <Route path={`/login`}>
              <LoginPage appID={appID} />
            </Route>
          </Switch>
        )
      }
      {window.innerWidth >= 500 ? <Footer /> : <></>}
    </div >
  );
}

export default App;
