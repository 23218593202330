/* eslint-disable react/no-danger */
import React from 'react';
import styles from './panel.module.scss';

function PanelRow({ children }) {
  return <article className={styles.panelRow}>{children}</article>;
}

function PanelTile({ children }) {
  return (
    <article className={`${styles.panel} ${styles.none}`}>{children}</article>
  );
}

function Panel({ children, className = '', padding = 'wide', raised = false }) {
  return (
    <section
      className={`${styles.panel} ${raised ? styles.raised : ''} ${
        styles[padding]
      } ${className}`}
    >
      {children}
    </section>
  );
}

export { Panel as default, PanelRow, PanelTile };
