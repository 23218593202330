/* eslint-disable import/prefer-default-export */
import React from 'react';
import { ThemeProvider } from 'emotion-theming';

const theme = {
  colors: {
    offWhite: '#fefefe',
    bgGray: '#f5f7fb',
    whiteGray: '#ddd',
    grayedOut: '#767676',
    darkGray: '#555',
    darkGrayShade: '#e8e8e8',
    lightestBlack: '#444',
    lighterBlack: '#333',
    lightBlack: '#222',
    darkBlack: '#000',
    lightBlue: '#3498db',
    lightBlueShade: '#2f89c5',
    darkBlue: '#2980b9',
    lightRed: '#e74c3c',
    blueGray: '#34495e',
    lightGreen: '#2ecc71',
    darkGreen: '#27ae60',
    gold: '#f1c40f',
    goldShade: '#e5ba0e',
    lightPurple: '#9b59b6',
    darkPurple: '#8e44ad',
    lightTurquoise: '#1abc9c',
    darkTurquoise: '#16a085',
  },

  general: {
    smBoxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
    smBoxShadowReverse: '0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
    lgBoxShadow: '0 3px 8px rgba(0, 0, 0, 0.25)',
    buttonBoxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)',
    headerBoxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)',

    gutterWidth: '1.25rem',
    headerHeight: '4rem',
    footerHeight: '11rem',
  },
};

const CustomThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export { CustomThemeProvider as ThemeProvider };
