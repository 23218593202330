import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import useLocalStorage from 'hooks/use-local-storage';
import {
  getR2gUserFromAuthID,
  getR2gUserFromUUID,
  getR2gStats,
  getQR2GSchoolsUserFromUUID,
  QR2GSchoolsLogin,
  getStoresInfo
} from 'utils/api';
import { FullScreenLoader } from 'components/loader/loader';

const loadStates = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState();
  const [user, setUser] = useState({});
  const [userID, setUserID] = useLocalStorage('user_id', '');
  const [appID, setAppID] = useLocalStorage('app_id', 1);
  const [store, setStore] = useLocalStorage('store',
    {
      "companyID": 63047,
      "CompanyName": "Boston , MA : 1 Lovejoy Wharf",
      "Address_1": "1 Lovejoy Wharf",
      "city": "Boston ",
      "State_code": "MA",
      "Latitude": 42.367373,
      "Longitude": -71.059595,
      "accountname": "Converse",
      "accountid": 79,
      "ShortCode": "BWRBLX"
    }
  )
  const [showHeader, setShowHeader] = useState(true)
  const loadRef = useRef(loadStates.NOT_LOADED);
  const [loadState, setLoadState] = useState(loadStates.LOADING);
  const updateLoadState = useCallback((newState) => {
    loadRef.current = newState;
    setLoadState(newState);
  }, []);
  const loginSource = useRef(
    qs.parse(window.location.search.slice(1)).ref || 'none'
  );
  // console.log("window.location.origin", window.location.origin)
  const auth0User = useAuth0().user;
  const { logout } = useAuth0()

  const updateUserStats = async () => {
    if (user?.UserID) {
      const UserStats = await getR2gStats(user.UserID, appID);
      setUser((prevUser) => ({
        ...prevUser,
        UserStats,
      }));
    }
  };

  const login = async (email, pass, identityProvider,) => {
    // updateLoadState(loadStates.LOADING);
    const userResponse = await QR2GSchoolsLogin(email, pass, identityProvider, appID)
    if (userResponse.Success) {
      updateLoadState(loadStates.LOADED);
      setUserID(userResponse.UUID);
      window.gtag('set', { userId: userResponse.UUID });
      window.gtag('event', 'login', { method: 'user-password' });
      setUser(userResponse);
      setLoggedIn(true)
      updateUserStats()

      return userResponse
    }
    setUser('');
    setLoggedIn(false);
    return userResponse
  }

  const attemptLoginWithUserID = async () => {
    //  console.log("attempt login ith authid")
    const userResponse = await getR2gUserFromAuthID(auth0User.sub);
    if (userResponse.Success) {
      updateLoadState(loadStates.LOADED);
      setUserID(userResponse.UUID);
      window.gtag('set', { userId: userResponse.UUID });
      window.gtag('event', 'login', { method: 'auth0' });
      setUser(userResponse);
      setLoggedIn(true);
      updateUserStats()
    }
  };

  const getSpecificStoreInfo = async () => {
    const storeJson = await getStoresInfo(79, 63047, "BWRBLX")
    const parsed = JSON.parse(storeJson.dsString).Table

    //console.log("running get specific store info", parsed)

    setStore(parsed[0])

  }

  // useEffect(() => {
  //   if (loginSource.current == "app") {
  //     setShowHeader(false)
  //   }
  //   if (searchVals.app_id !== undefined) {
  //     setAppID(searchVals.app_id)
  //     if (searchVals.app_id.length > 1) {
  //       console.log("searchVals.app_id.length", searchVals.app_id.length, searchVals.app_id[0])
  //       setAppID(searchVals.app_id[0])
  //     }
  //   }
  //   getSpecificStoreInfo()
  // }, [appID, user])

  useEffect(() => {
    // console.log("use effect 1 attempt login with uuid", loadRef.current, loadRef, loadState)
    if (loadRef.current === loadStates.NOT_LOADED) {
      updateLoadState(loadStates.LOADING);

      if (document.location.href.includes("qr2gschools")) {
        setAppID(4)
      }
      const searchVals = qs.parse(window.location.search.slice(1));
      const userIdForLogin = searchVals.user_uuid || userID || '';
      if (loginSource.current == "app") {
        setShowHeader(false)
      }
      if (searchVals.app_id !== undefined) {
        setAppID(searchVals.app_id)
        if (searchVals.app_id.length > 1) {
          setAppID(searchVals.app_id[0])
        }
      }
      updateLoadState(loadStates.NOT_LOADED);
      if (userIdForLogin) {
        if (appID == 4) {
          getSpecificStoreInfo()
          getQR2GSchoolsUserFromUUID(userIdForLogin, appID).then((userResponse) => {
            //  console.log(" in qr2g userResponse", userResponse)
            if (userResponse.Success) {
              updateLoadState(loadStates.LOADED);
              setUserID(userResponse.UUID);
              window.gtag('set', { userId: userResponse.UUID });
              window.gtag('event', 'login', { method: 'userUuid', url: window.location });
              setUser(userResponse);
              setLoggedIn(true);
              updateUserStats()
            } else {
              setLoggedIn(false);
            }
          })
        } else {
          getR2gUserFromUUID(userIdForLogin).then((userResponse) => {
            if (userResponse.Success) {
              updateLoadState(loadStates.LOADED);
              setUserID(userResponse.UUID);
              window.gtag('set', { userId: userResponse.UUID });
              window.gtag('event', 'login', { method: 'userUuid' });
              setUser(userResponse);
              setLoggedIn(true);
              updateUserStats()
            } else {
              setLoggedIn(false);

            }
          })
        }
      } else {
        setLoggedIn(false);
      }

      if (auth0User?.sub) {
        attemptLoginWithUserID();
      }
    }

  }, [userID, setUserID, auth0User, updateLoadState]);


  const logoutUser = () => {
    logout()
    setLoggedIn(false);
    setUserID('')
    setUser({})
    setAppID(1)
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, updateUserStats, appID, setAppID, login, logoutUser, setLoggedIn, showHeader, store, getSpecificStoreInfo }}>
      {isLoggedIn === undefined || loadState === loadStates.LOADING ? (
        <FullScreenLoader />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const authValue = useContext(AuthContext);
  return authValue;
};

export { AuthContext as default, AuthProvider, useAuth };
