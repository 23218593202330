import React, { useState } from 'react';
import Page from 'components/page/page';
import Panel from 'components/panel/panel';
import Button from 'components/button/button';
import { css } from 'emotion';
import 'app.scss';
import { useAuth } from 'contexts/auth-context';


function LoginPage() {
  //console.log("in login page", navigator.userAgent)
  const { login, appID, setLoggedIn } = useAuth();
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, pass } = document.forms[0];
    //console.log("in handle submit", e, email.value, pass.value)
    const res = await login(email.value, pass.value, navigator.userAgent, 4)
    //console.log("res", res.Success, res)
    if (res.Success) {
      setLoggedIn(true)
    } else {
      //console.log("res false", res.Success, res)
      setErrorMessages({ name: "email", message: res.Message })
      setLoggedIn(false)
    }
  }

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  return (
    <Page>
      <Panel>
        <div className="login-page">
          <h1>Login Below</h1>
          <form >
            <div className="input-container">
              <label htmlFor='email'>Email: </label>
              <input type="text" name="email" required />
              {renderErrorMessage("email")}
            </div>
            <div className="input-container">
              <label htmlFor='pass'>Password: </label>
              <input type="password" name="pass" required />
              {renderErrorMessage("pass")}
            </div>

            <div
              className={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Button
                size="large"
                onClick={(e) => handleSubmit(e)}
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </Panel>
    </Page >
  )

}

export default LoginPage;
