/* eslint-disable import/prefer-default-export */
import qs from 'qs';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

// console.log("process.env", process.env)


function fetchWithTimeout(url, options, timeout, onTimeout) {
  const timer = new Promise((resolve) => {
    setTimeout(resolve, timeout, {
      timeout: true,
    });
  });
  return Promise.race([fetch(url, options), timer]).then((response) => {
    if (response.timeout) {
      onTimeout();
    }
    return response;
  });
}

export async function getCauseList(params) {
  // console.log("params", params, qs.stringify(params))
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_CDDS_API_ENDPOINT
    }/api/Cause/CDDSCauses?${qs.stringify(params)}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    10000,
    () => {
      throw new Error('Session Timed out');
    }
  );
  if (!response.ok || response.status !== 200) {
    throw new Error('There was an error processing your session request');
  }

  const data = await response.json();
  // console.log('Get R2g Web Session', data);
  return data;
}

export async function getStoresInfo(accountID, companyID, shortCode = '') {
  // console.log('getR2gUserTags', UserID);
  const response = await fetch(`${API_ENDPOINT}/GetQR2GSchStores`, {
    body: JSON.stringify({
      AccountID: accountID,
      CompanyID: companyID,
      ShortCode: shortCode,

    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  if (response.ok) {
    const data = await response.json();
    const ds = data.d
    return ds
  }
  return {
    Success: false,
    Message: 'There was an error processing your request, please try again.',
  }
}

export async function QR2GSchoolsLogin(email, password, identityProvider, appID) {
  const response = await fetch(`${API_ENDPOINT}/Login`, {
    body: JSON.stringify({ Email: email, Password: password, Origin: 'user-password', IdentityProvider: identityProvider, AppID: appID }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
  if (response.ok) {
    const data = await response.json()
    if (!data.d.Success) {
      console.warn('ERROR GETTING USER:\n', JSON.stringify(data, null, 2))
    }
    return data.d
  }
  console.warn('ERROR GETTING USER:\n', JSON.stringify(response, null, 2))


  return {
    Success: false,
    Message: 'There was an error processing your request, please try again.',
  }
}

export async function getQR2GSchoolsUserFromUUID(UUID, appID) {
  // console.log('get r2g use', UUID);
  const response = await fetch(`${API_ENDPOINT}/GetUserFromUUID`, {
    body: JSON.stringify({ UUID, TimezoneOffset: 1, AppID: appID }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
  if (response.ok) {
    const data = await response.json()
    if (!data.d.Success) {
      console.warn('ERROR GETTING USER:\n', JSON.stringify(data, null, 2))
    }
    return data.d
  }
  console.warn('ERROR GETTING USER:\n', JSON.stringify(response, null, 2))


  return {
    Success: false,
    Message: 'There was an error processing your request, please try again.',
  }
}

export const getR2gUserFromAuthID = async (AuthID) => {
  const response = await fetch(
    `${API_ENDPOINT}/GetRv2gUserFromAuthID`,
    {
      body: JSON.stringify({
        AuthID,
        TimezoneOffset: new Date().getTimezoneOffset(),
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  // console.log(response, 'my response for logging in');
  if (response.ok) {
    const data = await response.json();
    // console.log('User Response Data', data);
    return data.d;
  }

  return { Success: false };
};



export const getR2gUserFromUUID = async (UUID) => {
  const response = await fetch(
    `${API_ENDPOINT}/GetRv2gUserFromUUID`,
    {
      body: JSON.stringify({
        UUID,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  // console.log(response, 'response for uuid');
  if (response.ok) {
    const data = await response.json();
    // console.log('User Response Data', data);
    return data.d;
  }

  return { Success: false };
};

export const getR2gAllOutstandingDonations = async ({
  UserUUID,
  TimezoneOffset,
  appID
}) => {
  let outDonEndpoint = `${API_ENDPOINT}/GetRv2gAllOutstandingDonations`
  if (appID == 4) {
    outDonEndpoint = `${API_ENDPOINT}/GetQR2GSchoolsAllOutstandingDonations`
  }


  const response = await fetch(
    outDonEndpoint,
    {
      body: JSON.stringify({
        UserUUID,
        TimezoneOffset,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    // console.log('Outstanding Donations Data', data.d);
    return data.d;
  }

  return { Success: false };
};

export const getqR2gDonationHistory = async ({ UserUUID, AppID }) => {

  let outDonEndpoint = `${API_ENDPOINT}/GetRv2gDonationHistory`
  if (AppID == 4) {
    outDonEndpoint = `${API_ENDPOINT}/GetQR2GSchoolsDonationHistory`
  }
  const response = await fetch(
    outDonEndpoint,
    {
      body: JSON.stringify({
        UserUUID,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    const causeArr = [];
    const dateArr = [];
    data.d.DonationListNew = [];

    //console.log("data from server", data)
    if (data.d.DonationList.length == 0) {
      return data.d
    }
    if (AppID == 4) {
      data.d.MainAccount = {
        AccountLogo: data.d.DonationList[0].StoreAccount.AccountLogo.Original,
        AccountName: data.d.DonationList[0].StoreAccount.AccountName,
        AccountID: data.d.DonationList[0].StoreAccount.AccountID
      }
    }
    // need to have each cause and date with the cause in a list

    data.d.DonationList.map((eachDon) => {
      if (dateArr.includes(eachDon.DateRedeemed)) {
        const index = data.d.DonationListNew.findIndex(
          (el) => el.DateRedeemed === eachDon.DateRedeemed
        );
        data.d.DonationListNew[index].TotalDonations += eachDon.TotalDonations;
        data.d.DonationListNew[index].Sponsors.push({
          AccountLogo: eachDon.SponsorAccount.AccountLogo,
          AccountName: eachDon.SponsorAccount.AccountName,
          DonationAmount: eachDon.TotalDonations,
        });
      } else {
        //  causeArr.push(eachDon.CauseID);
        dateArr.push(eachDon.DateRedeemed);
        data.d.DonationListNew.push({
          CauseID: eachDon.CauseID,
          DateRedeemed: eachDon.DateRedeemed,
          Sponsors: [
            {
              AccountLogo: eachDon.SponsorAccount.AccountLogo,
              AccountName: eachDon.SponsorAccount.AccountName,
              DonationAmount: eachDon.TotalDonations,
            },
          ],
          TotalDonations: eachDon.TotalDonations,
          Name: eachDon.LastCause.Name,
        });
      }
    })

    return data.d;
  }

  return { Success: false };
};

export const submitR2gDonation = async ({
  UserUUID,
  Donation,
  TimezoneOffset,
  AppID
}) => {
  const response = await fetch(
    `${API_ENDPOINT}/SubmitRv2gDonation`,
    {
      body: JSON.stringify({
        UserUUID,
        Donation,
        TimezoneOffset,
        AppID
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  // console.log(response, 'my donation submit response');
  if (response.ok) {
    const data = await response.json();
    // console.log('Donation Submition Data', data.d);
    return data.d;
  }

  return { Success: false };
};

export const submitQR2GSchoolDonation = async ({
  UserUUID,
  Donation,
  TimezoneOffset,
  AppID
}) => {
  const response = await fetch(
    `${API_ENDPOINT}/SubmitQR2GSchoolsDonation`,
    {
      body: JSON.stringify({
        UserUUID,
        Donation,
        TimezoneOffset,
        AppID
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  // console.log(response, "my donation submit response")
  if (response.ok) {
    const data = await response.json();
    //   console.log('Donation Submition Data', data.d);
    return data.d;
  }

  return { Success: false };
};


export const getR2gStats = async (UserID, appID) => {
  let statsEndpoint = `${API_ENDPOINT}/GetRv2gStats`
  if (appID == 4) {
    statsEndpoint = `${API_ENDPOINT}/GetQR2GSchoolsStats`
  }
  const response = await fetch(statsEndpoint,
    {
      body: JSON.stringify({
        UserID,
        TimezoneOffset: new Date().getTimezoneOffset(),
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    // console.log('User Response Data', data);
    return data.d;
  }

  return { Success: false };
};

export const submitR2gPageView = async ({
  UserID,
  AccountID,
  SponsorID,
  Pathname,
  Ref,
}) => {
  const response = await fetch(
    `${API_ENDPOINT}/SubmitRv2gPageView`,
    {
      body: JSON.stringify({
        PageView: {
          UserID,
          AccountID,
          SponsorID,
          Pathname,
          Ref,
          TimezoneOffset: new Date().getTimezoneOffset(),
        },
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    return data.d;
  }

  return { Success: false };
};

export const getQR2GSchoolStats = async (UserID) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetQR2GSchoolsStats`,
    {
      body: JSON.stringify({
        UserID,
        TimezoneOffset: new Date().getTimezoneOffset(),
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    // console.log('User Response Data', data);
    return data.d;
  }

  return { Success: false };
};
