import React from 'react';
import Page from 'components/page/page';
import DonationList from 'components/donation-list/donation-list';
import StatsRow from 'components/stats-row/stats-row';
import { useAuth } from 'contexts/auth-context';

function HomePage() {
  const { appID } = useAuth();
  return (
    <Page className="hasHeader">
      <DonationList appID={appID} />
      <StatsRow appID={appID} />
      {/* <BadgeListPanel /> */}
    </Page>
  );
}

export default HomePage;
