import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import Panel from 'components/panel/panel';
import { useDonations } from 'contexts/donation-context';
import { formatMoney } from 'utils/money';
import Loader from 'components/loader/loader';
import { useAuth } from 'contexts/auth-context';

import './donation-list.scss';

const getDonationKey = (donation) =>
  `${donation.StoreAccount.AccountID}-${donation.SponsorAccount.AccountID}`;

const getDonationPath = (donation) =>
  `/redeem/${donation.StoreAccount.AccountID}-${donation.SponsorAccount.AccountID}/`;

const getQR2GSchoolsDonationKey = (donation) =>
  `${donation.StoreAccount.AccountID}-${donation.RevisitTypeID}`;

const getQR2GSchoolsDonationPath = (donation) =>
  `/redeem/${donation.StoreAccount.AccountID}-${donation.RevisitTypeID}/`;

const QR2GSchoolsDonationItem = ({ donation }) => {
  //console.log("donation", donation)
  return (
    <Link
      className={css`
        display: block;
        margin-bottom: 1rem;
        text-decoration: none;
      `}
      to={getQR2GSchoolsDonationPath(donation)}
    >
      <div className="donation-item-body">
        <div className="donation-item-text-container">
          <div className="donation-item-image-container">
            <div
              className="donation-item-image"
              style={{
                backgroundImage: `url(${donation.StoreAccount.AccountLogo.Original})`,
              }}
            />
            <h2 className="donation-item-header">
              {donation.StoreAccount.AccountName}
            </h2>
          </div>
          <div className="donation-item-amount-container">
            <div className="donation-item-amount-tile">
              <div className="donation-item-amount-value">
                {formatMoney(donation.TotalDonations)}
              </div>
              <div className="donation-item-amount-label">
                {donation.RevisitTypeID == 1 ? "Outstanding Donations from Store Scans" : "Outstanding Donations from Purchases"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link >
  );
}

const RV2GDonationItem = ({ donation }) => {
  return (
    <Link
      className={css`
        display: block;
        margin-bottom: 1.5rem;
        text-decoration: none;
      `}
      to={getDonationPath(donation)}
    >
      <div className="donation-item-body">
        <div className="donation-item-text-container">
          <div className="donation-item-image-container">
            <div
              className="donation-item-image-header"
              style={{
                backgroundImage: `url(${donation.StoreAccount.AccountLogo.Small})`,
              }}
            />
            <h2 className="donation-item-header">
              {donation.StoreAccount.AccountName}
            </h2>
          </div>
          <div className="donation-item-amount-container">
            <div className="donation-item-amount-tile">
              <div className="donation-item-amount-value">
                {formatMoney(donation.TotalDonations)}
              </div>
              <div className="donation-item-amount-label">
                Outstanding Donations
              </div>
            </div>
          </div>
        </div>
        <table className="donation-sponsors">
          <thead>
            <tr className="donation-sponsor-header-container">
              <th className="donation-item-header-labels">Sponsor</th>
              <th className="donation-item-header-labels">Donations</th>
            </tr>
          </thead>

          <tbody>
            {donation.Sponsors.map((sponsor) => {
              return (
                <tr
                  className="donation-sponsor-item-body"
                  key={sponsor.AccountID}
                >
                  <td className="donation-sponsor-image-title">
                    <img
                      src={sponsor.AccountLogo.Small}
                      alt="Logo"
                      className="donation-sponsor-item-image"
                    />
                  </td>
                  <td className="donation-sponsor-item-amount-tile">
                    <div className="donation-sponsor-item-amount-value">
                      {formatMoney(sponsor.TotalDonations)}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Link>
  );
}


function RedeemList() {
  const { appID } = useAuth();
  const {
    donationList,
    donationLoadingState,
    donationLoadingStates,
  } = useDonations();

  const getDonationListBody = () => {
    var donationListCombined = [];
    var donationAccountCombined = [];
    var totalDonations = 0;

    if (donationLoadingState !== donationLoadingStates.LOADED) {
      return (
        <div className="donation-list-loading-container">
          <Loader />
        </div>
      );
    }
    const donationTotalAndList = [totalDonations, ...donationListCombined];
    //console.log("donationList", donationList)
    if (donationList && donationList[0]?.TotalDonations > 0) {
      if (appID == 4) {
        return donationList.map((donation) => (
          <QR2GSchoolsDonationItem donation={donation} key={getQR2GSchoolsDonationKey(donation)} />
        ))
      }
      return donationList.map((donation) => (
        <RV2GDonationItem donation={donation} key={getDonationKey(donation)} />
      ))
    }
    return (
      <div>
        <p>No donations available to redeem</p>
        <p>Submit a scan on the {appID == 4 ? 'QR2GSchools app' : 'QR 2Give app'} to receive donations!</p>
      </div>
    );
  };

  return (
    <Panel>
      <h2 className="page-title">Your Available Donations</h2>
      {donationList[0]?.TotalDonations > 0 && (
        <p className="subtitle">
          <span style={{ color: '#FF0000', fontWeight: 'bold' }}>Click</span>{' '}
          the <b>Outstanding Donations Dollar Amount</b> below to direct your
          donations
        </p>
      )}

      {getDonationListBody()}
    </Panel>
  );
}

export default RedeemList;
