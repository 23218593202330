import React from 'react';
import Page from 'components/page/page';
import DonationHistory from 'components/donation-history/donation-history';

function DonationHistoryPage() {
  return (
    <Page>
      <DonationHistory />
    </Page>
  );
}

export default DonationHistoryPage;
