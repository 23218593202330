import React from 'react';
import 'app.scss';
import { PanelRow } from 'components/panel/panel';
import LifetimeIcon from 'images/stats-icons/shopping-cart.svg';
import LifetimeDonationsIcon from 'images/stats-icons/mobile-payment-gray.svg'
import DonationIcon from 'images/stats-icons/puzzle-heart.svg'
import { useAuth } from 'contexts/auth-context';
import { formatMoney } from 'utils/money';
import StatsTile, {
  StatsTileIcon,
  StatsTileContent,
  StatsTileLabel,
  StatsTileValue,
} from './stats-tile';


const RV2GStats = ({ user }) => {
  const {
    LifetimeDonations,
    LifetimeRevisits,
    OutstandingDonations,
  } = user.UserStats;

  return (
    <PanelRow>
      <StatsTile>
        <StatsTileIcon icon={LifetimeIcon} alt="Lifetime Revisits" />
        <StatsTileContent>
          <StatsTileValue>{LifetimeRevisits}</StatsTileValue>
          <StatsTileLabel>{'Lifetime\nScans'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
      <StatsTile link="/donation-history" subLabel="Click Here for Details">
        <StatsTileIcon icon={LifetimeDonationsIcon} alt="Lifetime ReVisits" />
        <StatsTileContent>
          <StatsTileValue>{formatMoney(LifetimeDonations)}</StatsTileValue>
          <StatsTileLabel>{'Lifetime\nDonations'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
      <StatsTile>
        <StatsTileIcon icon={DonationIcon} alt="Outstanding Donations" />
        <StatsTileContent>
          <StatsTileValue>{formatMoney(OutstandingDonations)}</StatsTileValue>
          <StatsTileLabel>{'Ready 2'}</StatsTileLabel>
          <StatsTileLabel>{'Give!'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
    </PanelRow>
  )


}

const QR2GSchoolsStats = ({ user }) => {
  const {
    LifetimePurchaseDonations,
    LifetimePurchaseScans,
    LifetimeInStoreDonations,
    LifetimeInStoreScans,
    OutstandingPurchaseDonations,
    OutstandingStoreDonations,
    ScansThisMonth,
  } = user.UserStats;

  return (
    <PanelRow>
      <StatsTile>
        <StatsTileIcon icon={LifetimeIcon} alt="Lifetime Revisits" />
        <StatsTileContent>
          <StatsTileValue>{LifetimePurchaseScans + LifetimeInStoreScans}</StatsTileValue>
          <StatsTileLabel>{'Lifetime\nScans'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
      <StatsTile link="/donation-history" subLabel="Click Here for Details">
        <StatsTileIcon icon={LifetimeDonationsIcon} alt="Lifetime ReVisits" />
        <StatsTileContent>
          <StatsTileValue>{formatMoney(LifetimeInStoreDonations + LifetimePurchaseDonations)}</StatsTileValue>
          <StatsTileLabel>{'Lifetime\nDonations'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
      <StatsTile>
        <StatsTileIcon icon={DonationIcon} alt="Outstanding Donations" />
        <StatsTileContent>
          <StatsTileValue>{formatMoney(OutstandingStoreDonations + OutstandingPurchaseDonations)}</StatsTileValue>
          <StatsTileLabel>{'Ready 2'}</StatsTileLabel>
          <StatsTileLabel>{'Give!'}</StatsTileLabel>
        </StatsTileContent>
      </StatsTile>
    </PanelRow>
  )
}

function StatsRow() {
  const { user, appID } = useAuth();
  //console.log("user in stats", user)
  return (
    <PanelRow>
      {appID == 4 ? <QR2GSchoolsStats user={user} /> : <RV2GStats user={user} />}
    </PanelRow>
  );
}

export default StatsRow;
