import React from 'react';
import Button from 'components/button/button';
import './last-non-profit.scss';
import { useAuth } from 'contexts/auth-context';

const LastNonProfit = ({ nonProfit, setSelectedCause = () => { } }) => {
  const { appID } = useAuth();
  let headerName = 'Non-Profit'

  if (appID == 4) {
    headerName = 'School'
  }
  const handleDonateClick = () => {
    setSelectedCause({
      CauseID: nonProfit.CompanyID,
      CauseName: nonProfit.Name,
      CauseLocation: `${nonProfit.City}, ${nonProfit.State}`,
      CauseCategory: nonProfit.Category,
    });
  };

  return (
    <div className="last-non-profit-container">
      <div className="last-non-profit-content">
        <h2 className="last-non-profit-header">Your Last {headerName}</h2>
        <div className="last-non-profit">{nonProfit.Name}</div>
      </div>
      <Button onClick={handleDonateClick} size={'medium'} type={'secondary'}>
        Donate Again
      </Button>
    </div>
  );
};

export default LastNonProfit;
