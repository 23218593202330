import React from 'react';
import HeaderLogo from 'images/header-logo.png';
import QR2GHeaderLogo from 'images/header-logo-qr2gschools.png';
import { useAuth } from 'contexts/auth-context';
import './footer.scss';

const FooterLink = ({ children, link }) => (
  <a
    className="footer-resources-link"
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

function Footer() {
  const { appID } = useAuth();
  return (
    <footer id="app-footer">

      {appID == 4 ?
        <article className="footer-content">
          <section className="footer-content-left">
            <div className="footer-resources-header">Resources</div>
            <FooterLink link="https://www.qr2gschools.com/">
              Program Website
            </FooterLink>
            <FooterLink link="https://www.qr2gschools.com/faqs/">FAQs</FooterLink>
            <FooterLink link="https://www.qr2gschools.com/download/">
              Download the App
            </FooterLink>
            <FooterLink link="https://www.qr2gschools.com/privacy-policy-2/">
              Privacy Policy
            </FooterLink>
          </section>
          <section className="footer-content-left">
            <div className="footer-resources-header">Contact</div>
            <FooterLink link="mailto:helpdesk@qr2give.com">
              helpdesk@qr2give.com
            </FooterLink>
            <FooterLink link="tel:+16032223333">(603) 222-3333</FooterLink>
          </section>
          <section className="footer-content-right">
            <article className="footer-copyright-container">
              <img className="footer-logo" src={QR2GHeaderLogo} alt="QR2GSchools" />
              <span className="footer-copyright">© QR2GSchools 2022</span>
            </article>
          </section>
        </article>
        :
        <article className="footer-content">
          <section className="footer-content-left">
            <div className="footer-resources-header">Resources</div>
            <FooterLink link="https://www.revisit2give.com/">
              Program Website
            </FooterLink>
            <FooterLink link="https://www.revisit2give.com/faqs/">FAQs</FooterLink>
            <FooterLink link="https://www.revisit2give.com/download/">
              Download the App
            </FooterLink>
            <FooterLink link="https://www.revisit2give.com/privacy-policy-2/">
              Privacy Policy
            </FooterLink>
          </section>
          <section className="footer-content-left">
            <div className="footer-resources-header">Contact</div>
            <FooterLink link="mailto:helpdesk@revisit2give.com">
              helpdesk@qr2give.com
            </FooterLink>
            <FooterLink link="tel:+16032223333">(603) 222-3333</FooterLink>
          </section>
          <section className="footer-content-right">
            <article className="footer-copyright-container">
              <img className="footer-logo" src={HeaderLogo} alt="ReVisit 2 Give" />
              <span className="footer-copyright">© QR 2Give 2021</span>
            </article>
          </section>
        </article>}
    </footer >
  );
}

export default Footer;
