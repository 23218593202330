import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import RangeInput from 'components/range-input/range-input';
import Panel from 'components/panel/panel';
import useWindowSize from 'hooks/use-window-size';
import { getCauseList } from 'utils/api';
import 'react-table/react-table.css';
import './cause-table.scss';
import { useAuth } from 'contexts/auth-context';

function CauseTable({
  accountUID,
  userLatitude,
  userLongitude,
  userZipCode,
  causeView,
  setSelectedCause,
}) {
  const { screenSize } = useWindowSize();

  const [isLoading, setLoading] = useState(true);
  const [causeList, setCauseList] = useState();
  const [numPages, setNumPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [sorted, setSorted] = useState(
    [
      {
        id: 'CauseDistance',
        desc: true,
      },
    ]
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [causeDistance, setCauseDistance] = useState(50);
  const isDistanceDisabled = !!searchTerm;
  const { appID, store, getSpecificStoreInfo } = useAuth();

  let headerName = 'Non-Profit'

  if (appID == 4) {
    headerName = 'School'
  }

  const getColumns = () => {
    switch (screenSize) {
      case 'large':
        return [
          {
            Header: 'Name',
            id: 'CauseName',
            accessor: 'CauseName',
          },
          {
            Header: 'Category',
            accessor: 'CauseCategory',
            maxWidth: 150,
          },
          {
            Header: 'Location',
            accessor: 'CauseLocation',
            maxWidth: 150,
          },
          {
            Header: 'Distance',
            accessor: 'CauseDistance',
            maxWidth: 250,
            Cell: (row) => (
              <div style={{ textAlign: 'right' }}>{row.value} miles</div>
            ),
          },
        ];
      case 'medium':
        return [
          {
            Header: headerName,
            id: 'CauseName',
            accessor: 'CauseName',
          },
          {
            Header: 'Location',
            accessor: 'CauseLocation',
          },
        ];
      default:
        return [
          {
            Header: headerName,
            id: 'CauseName',
            width: 320,
            accessor: (d) => [d.CauseName, d.CauseLocation, d.CauseDistance],
            Cell: (row) => (
              <div>
                <div>{row.value[0]}</div>
                <div>{`${row.value[1]} - ${row.value[2]} miles away`}</div>
              </div>
            ),
          },
        ];
    }
  };

  const pageSize = screenSize === 'small' ? 10 : 15;

  const handleCauseClick = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          setSelectedCause(rowInfo.original);
        },
        style: {
          cursor: 'pointer',
        },
      };
    }
    return {};
  };

  useEffect(() => {
    let isCurrent = true;
    // causeView = '[dbo].[psimvw_cddssascause]'

    const getTableData = async () => {
      setLoading(true);
      if (!store?.lat || !store.long) {
        getSpecificStoreInfo()
      }

      let query = `select * from (select *,ISNULL([dbo].[UDF_Distance](latitude,longitude,${userLatitude},${userLongitude}),100)as causedistance from ${causeView}) as a where 1=1`;
      if (appID == 4) {
        query = `select * from (select *,ISNULL([dbo].[UDF_Distance](latitude,longitude,${store.Latitude ?? userLatitude},${store.Longitude ?? userLongitude}),100)as causedistance from ${causeView}) as a where 1=1`;
      }

      if (searchTerm !== '') {
        query += `and  (causename like '%${searchTerm.replace(
          "'",
          "''"
        )}%' or causecity like '${searchTerm.replace("'", "''")}%')`;
      } else {
        query += `and causeDistance <= ${causeDistance}`;
      }
      // console.log("causeView", causeView, query)
      const params = {
        userUID: '',
        accountUID,
        programUID: '',
        sqlStatement: query,
        lat: userLatitude,
        lng: userLongitude,
        distance: causeDistance,
        filter: '',
        pageNum,
        limit: pageSize,
        start: pageNum * pageSize,
        sort: []
      };

      if (sorted && sorted.length) {
        //  console.log("sorted", sorted)
        const arr = [];
        arr.push({
          property: sorted[0].id,
          direction: sorted[0].desc ? 'DESC' : 'ASC',
        });
        if (appID == 4) {
          params.sort = `[{
            "property": "${sorted[0].id === 'CauseLocation' ? 'CauseCity' : sorted[0].id
            }", "direction": ${sorted[0].desc ? '"ASC"' : '"DESC"'}}]`;
        } else {
          params.sort = `[{
              "property": "${sorted[0].id === 'CauseLocation' ? 'CauseCity' : sorted[0].id
            }", "direction": ${sorted[0].desc ? '"ASC"' : '"DESC"'}}]`;
          //  console.log("params.sort", params.sort, params)
        }
      }

      const causeResp = await getCauseList(params);
      // console.log("cause", causeResp)
      if (isCurrent) {
        const causes = causeResp.causes.map((cause) => ({
          ...cause,
          CauseLocation: `${cause.CauseCity}, ${cause.CauseState}`,
          // CauseDistance: cause.CauseDistance.toFixed(1),
        }));

        setCauseList(causes);
        setNumPages(Math.ceil(causeResp.totalCount / pageSize));
        setLoading(false);
      }
    };

    if (accountUID && userLatitude && userLongitude) {
      getTableData();
    }

    return () => {
      isCurrent = false;
    };
  }, [
    accountUID,
    causeDistance,
    causeView,
    pageNum,
    pageSize,
    searchTerm,
    sorted,
    userLatitude,
    userLongitude,
    appID
  ]);

  const fetchData = (state) => {
    setPageNum(state.page);
    setSorted(state.sorted);
  };

  return (
    <Panel>
      <h2>Select a New {appID == 4 ? 'School' : 'Non-Profit'}</h2>
      <div className="table-controls">
        <div className="form-group">
          <label htmlFor="causeSearchInput">Search {appID == 4 ? 'Schools' : 'Non-Profits'}</label>
          <input
            type="text"
            className="form-control"
            id="causeSearchInput"
            aria-describedby="causeSearch"
            placeholder="Enter School Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="form-group">
          <RangeInput
            label={`Within ${causeDistance} miles of ${userZipCode}`}
            min={10}
            max={200}
            value={causeDistance}
            onChange={(newDistance) => setCauseDistance(newDistance)}
            step={10}
            disabled={isDistanceDisabled}
            editable={false}
            showValue={false}
            className="form-control"
          />
        </div>
      </div>
      <ReactTable
        columns={getColumns()}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={causeList}
        showPaginationTop
        showPaginationBottom={false}
        pages={numPages} // Display the total number of pages
        loading={isLoading} // Display the loading overlay when we need it
        onFetchData={fetchData}
        pageSize={pageSize}
        showPageSizeOptions={false}
        showPageJump={false}
        defaultSorted={[
          {
            id: 'CauseDistance',
            desc: 'ASC',
          },
        ]}
        className="-striped -highlight"
        resizable={false}
        page={pageNum}
        getTrProps={handleCauseClick}
      />
    </Panel>
  );
}

export default CauseTable;
