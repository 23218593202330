import React from 'react';
import Panel from 'components/panel/panel';
import styles from './loader.module.scss';

function Loader({ color = '#222', size = 40 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
}

function FullScreenLoader({ color = '#222', loaderSize = 40 }) {
  return (
    <section className={styles['full-screen-loader']}>
      <Loader color={color} width={loaderSize} />
    </section>
  );
}

function PanelLoader({ color = '#222', loaderSize = 40 }) {
  return (
    <Panel className={styles['panel-loader']}>
      <section className={styles.spacer}>
        <Loader color={color} width={loaderSize} />
      </section>
    </Panel>
  );
}

export { FullScreenLoader, PanelLoader };

export default Loader;
