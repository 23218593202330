import React from 'react';
import styles from './page.module.scss';

function Page({ children, className = '', id = '', size = 'small' }) {
  return (
    <article id={id} className={`${styles.page} ${className} ${styles[size]}`}>
      {children}
    </article>
  );
}

export default Page;
