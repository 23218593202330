/* eslint-disable import/prefer-default-export */
import moment from 'moment';

const MS_IN_SECOND = 1000;
const MS_IN_MINUTE = MS_IN_SECOND * 60;
const MS_IN_HOUR = MS_IN_MINUTE * 60;

export const parseDotNetDate = (date) => {
  const match = date.match(/(\d+)([+-])(\d+)/);
  let ms = parseInt(match[1], 10);
  const sign = match[2];
  const hours = parseInt(match[3].slice(0, 2), 10);
  const minutes = parseInt(match[3].slice(2), 10);
  let timezoneMS = hours * MS_IN_HOUR + minutes * MS_IN_MINUTE;
  timezoneMS = sign === '-' ? timezoneMS * -1 : timezoneMS;
  ms += timezoneMS;
  return new Date(ms);
};

export const parseDotNetDateMonthDay = (date) => {
  const match = date.match(/(\d+)([+-])(\d+)/);
  let ms = parseInt(match[1], 10);
  const sign = match[2];
  const hours = parseInt(match[3].slice(0, 2), 10);
  const minutes = parseInt(match[3].slice(2), 10);
  let timezoneMS = hours * MS_IN_HOUR + minutes * MS_IN_MINUTE;
  timezoneMS = sign === '-' ? timezoneMS * -1 : timezoneMS;
  ms += timezoneMS;
  //console.log(ms, 'my');
  return moment(ms, 'x').format('MM/DD/YY');
};
