import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import ReactGA from 'react-ga';
import 'index.scss';
import { AuthProvider } from 'contexts/auth-context';
import { DonationProvider } from 'contexts/donation-context';
import { ThemeProvider } from 'contexts/theme-context';
import App from 'app';
import * as serviceWorker from 'service-worker';
import { Auth0Provider } from '@auth0/auth0-react';

// ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
  >
    <AuthProvider>
      <BrowserRouter>
        <DonationProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </DonationProvider>
      </BrowserRouter>
    </AuthProvider>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
