import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { User } from 'react-feather';
import HeaderLogo from 'images/header-logo.png';
import QR2GSchoolsHeaderLogo from 'images/header-logo-qr2gschools.png';
import { useAuth } from 'contexts/auth-context';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/button/button';
import { Home } from 'react-feather';
import './header.scss';

function Header({ showHeader }) {
  const { isLoggedIn, user, appID, logoutUser } = useAuth();
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();

  const location = useLocation();
  const isRedeemPage = location?.pathname.toLowerCase().includes('redeem');
  return (
    <header id="header" className={isRedeemPage ? 'redeem' : ''}>
      {showHeader && <section className="header-content">
        <section className="left-content">
          {appID == 4 ?
            <Link to="/" className="home-button">
              <img src={QR2GSchoolsHeaderLogo} alt="QR2GSchools" />
              <div className="header-text">QR2GSchools</div>
            </Link>
            : <Link to="/" className="home-button">
              <img src={HeaderLogo} alt="QR 2Give" />
              <div className="header-text">QR 2Give</div>
            </Link>
          }
        </section>
        <section className="right-content">
          {isLoggedIn ? (<Link to="/contact-info" className="header-button">
            <Home size={28} color="#333" />
            <div className="user-name">{user.FirstName}</div>
          </Link>
          ) : (
            <Button
              size="small"
              onClick={() => {
                if (appID == 4) {
                  history.push('/login')
                } else {
                  loginWithRedirect({ prompt: 'login' })
                }
              }} >
              Sign In
            </Button>)}
        </section>
      </section>}
      {
        window.location.origin.includes("local") && <Button
          size="small"
          onClick={() => {
            logoutUser()
          }} >
          Logout
        </Button>
      }
    </header >
  );
}


export default Header;
