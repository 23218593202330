import React, { useState, useEffect, useCallback } from 'react';
import { CheckCircle } from 'react-feather';
import Button from 'components/button/button';
import Loader from 'components/loader/loader';
import { submitR2gDonation, submitQR2GSchoolDonation } from 'utils/api';
import { useAuth } from 'contexts/auth-context';
import { useDonations } from 'contexts/donation-context';
import './confirmation-modal.scss';

const modalStates = {
  WAITING: 'WAITING',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  DONE: 'DONE',
  FAILURE: 'FAILURE',
};

const ConfirmationModalSponsor = ({ headerName, sponsor, appID }) => {
  return (
    <div className="confirmation-modal-sponsor">
      <div>
        {/* <img
          className="confirmation-modal-sponsor-logo"
          src={sponsors.AccountLogo.Small}
          alt={sponsors.AccountName}
        /> */}
      </div>
      <p className="confirmation-modal-sponsor-message">
        These donations are proudly provided by {appID == 4 ? (<b>{sponsor.AccountName}</b>)
          : sponsor.map((each, i) => (<b>{i > 0 && (<b>, </b>)}{each.AccountName}</b>)
          )} in support of local {headerName}.</p>
    </div>
  );
};

export default function ConfirmationModal({
  sponsors = {},
  hidden = true,
  selectedCause,
  closeModal,
  amount = '$0.00',
  donationObj = {},
}) {
  // Local selected cause to prevent flash during Cancel animation
  const [localSelectedCause, setLocalSelectedCause] = useState(selectedCause);

  useEffect(() => {
    let isCurrent = true;

    if (selectedCause) {
      setLocalSelectedCause(selectedCause);
    } else {
      setTimeout(() => {
        if (isCurrent) {
          setLocalSelectedCause(selectedCause);
        }
      }, 200);
    }

    return () => {
      isCurrent = false;
    };
  }, [selectedCause]);

  const { refreshDonations, donationList } = useDonations();

  const { user, updateUserStats, appID } = useAuth();
  const [modalState, setModalState] = useState(modalStates.WAITING);

  let headerName = 'non-profits'

  if (appID == 4) {
    headerName = 'schools'
  }

  const sendMessageAndRefreshDonations = (e) => {
    refreshDonations()
    //console.log("donationList", donationList)
    if (donationList.length == 0) {
      //console.log("donationList is 0", donationList)
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          `url:${user.UUID.toString()}`
        );
      }
    }

  }

  const submitDonation = useCallback(async () => {
    setModalState(modalStates.LOADING);
    // TODO: Add error handling
    //console.log(donationObj, "my donation obj")
    await submitR2gDonation({
      UserUUID: user.UUID,
      Donation: {
        ...donationObj,
        CauseID: selectedCause.CauseID,
      },
      TimezoneOffset: new Date().getTimezoneOffset(),
      AppID: appID
    });


    window.gtag('event', 'donate', { donationObj });
    updateUserStats();
    setModalState(modalStates.SUCCESS);
  }, [donationObj, user, selectedCause, updateUserStats]);

  const getModalContent = () => {
    switch (modalState) {
      case modalStates.WAITING:
        return (
          <>
            <div className="confirmation-modal-content">
              <h4 className="confirmation-modal-header">
                You are directing your{' '}
                <span className="confirmation-modal-value">{amount}</span>{' '}
                donation to:
              </h4>
              {localSelectedCause && (
                <>
                  <h4 className="confirmation-modal-header confirmation-modal-cause">
                    {localSelectedCause.CauseName}
                  </h4>
                  <p className="confirmation-modal-description">
                    {localSelectedCause.CauseLocation}
                  </p>
                  <p className="confirmation-modal-description">
                    Category: {localSelectedCause.CauseCategory}
                  </p>
                </>
              )}
            </div>
            <ConfirmationModalSponsor sponsor={sponsors} headerName={headerName} appID={appID} />
            <div className="confirmation-modal-button-section">
              <Button onClick={closeModal}>Cancel</Button>
              <Button onClick={submitDonation}>Submit</Button>
            </div>
          </>
        );

      case modalStates.LOADING:
        return (
          <div className="confirmation-modal-loader-container">
            <Loader />
          </div>
        );

      case modalStates.SUCCESS:
        return (
          <>
            <div className="confirmation-modal-content">
              <h2 className="confirmation-modal-title">Success!</h2>
              <h4 className="confirmation-modal-header">
                You have successfully donated{' '}
                <span className="confirmation-modal-value">{amount}</span> to{' '}
                <span className="confirmation-modal-inline-cause">
                  {localSelectedCause?.CauseName}
                </span>
              </h4>
            </div>
            <ConfirmationModalSponsor sponsor={sponsors} headerName={headerName} appID={appID} />
            <div className="confirmation-modal-button-section">
              <Button onClick={sendMessageAndRefreshDonations}>Done</Button>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={`confirmation-modal-page ${hidden && 'hidden'}`}>
      <div className="confirmation-modal-window">
        <div className="confirmation-modal-icon-header">
          <CheckCircle color="#fff" size={36} />
        </div>
        {getModalContent()}
      </div>
    </div>
  );
}
