import React, { Fragment, useEffect, useState } from 'react';
import Panel from 'components/panel/panel';
import { getqR2gDonationHistory } from 'utils/api';
import { parseDotNetDateMonthDay } from 'utils/date';
import Banner from 'images/Converse-Donation-Hist.png'
import { formatMoney } from 'utils/money';
import { useAuth } from 'contexts/auth-context';
import Loader from 'components/loader/loader';
import { css } from 'emotion';
import { useTheme } from 'emotion-theming';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const DonationHistoryColumn = ({
  header = false,
  right = false,
  flex = 1,
  children,
  margin = 0,
}) => (
  <article
    className={css`
      font-weight: ${header ? 'bold' : 'regular'};
      text-align: ${right ? 'right' : 'left'};
      flex: ${flex};
      margin-right: 15px;
      font-size: 14px;
    `}
  >
    {children}
  </article>
);

const DonationHistoryListItem = ({ children }) => {
  const { colors } = useTheme();
  return (
    <li
      className={css`
        display: flex;
        padding: 0.5rem 0;
      `}
    >
      {children}
    </li>
  );
};

const DonationHistoryList = ({ children }) => (
  <ul
    className={css`
      list-style: none;
      padding: 0;
    `}
  >
    {children}
  </ul>
);

const LoaderContainer = ({ children }) => (
  <div
    className={css`
      height: 16rem;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    {children}
  </div>
);


const Rv2GDonationHistory = ({ isDonationHistoryLoading, donationHistoryList }) => {
  if (donationHistoryList.length == 0) {
    return (
      <DonationHistoryList>
        <DonationHistoryListItem>
          <DonationHistoryColumn header left>
            Date
          </DonationHistoryColumn>
          <DonationHistoryColumn header flex={1}>
            Non-Profit
          </DonationHistoryColumn>
          <DonationHistoryColumn header right>
            Amount
          </DonationHistoryColumn>
        </DonationHistoryListItem>
        <DonationHistoryListItem key={1}>
          {' '}
          <DonationHistoryColumn left />
          {/* {donation.SponsorAccount.AccountName} */}
          <DonationHistoryColumn flex={2} header>
            No Donations Yet
          </DonationHistoryColumn>
          <DonationHistoryColumn right />
        </DonationHistoryListItem>{' '}
      </DonationHistoryList >
    )
  }
  // donationHistoryList.Sponsors.filter()

  if (isDonationHistoryLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  return (
    <DonationHistoryList>
      <DonationHistoryListItem>
        <DonationHistoryColumn header left>
          Date
        </DonationHistoryColumn>
        <DonationHistoryColumn header flex={1}>
          Non-Profit
        </DonationHistoryColumn>
        <DonationHistoryColumn header right>
          Amount
        </DonationHistoryColumn>
      </DonationHistoryListItem>

      {donationHistoryList.map((donation) => {
        return (
          <Fragment key={donationHistoryList.indexOf(donation)}>
            <DonationHistoryListItem key={donation.DateRedeemed}>
              {' '}
              <DonationHistoryColumn left>
                {parseDotNetDateMonthDay(donation.DateRedeemed)}
              </DonationHistoryColumn>
              {/* {donation.SponsorAccount.AccountName} */}
              <DonationHistoryColumn flex={2} header>
                {donation.Name}
              </DonationHistoryColumn>
              <DonationHistoryColumn right>
                {formatMoney(donation.TotalDonations)}
              </DonationHistoryColumn>
            </DonationHistoryListItem>{' '}
            <div
              style={{
                borderBottom: `2px solid #ddd`,
                alignItems: 'center',
                textAlign: 'center',
                padding: '10px',
                marginBottom: '10px',
              }}
            >
              <h4>Provided by:</h4>
              {donation.Sponsors.map((image) => (
                <img
                  src={image.AccountLogo.Original}
                  alt={image.AccountName}
                  key={donation.Sponsors.indexOf(image)}
                  style={{
                    height: '4rem',
                    alignSelf: 'center',
                    margin: '10px',
                  }}
                  title={formatMoney(image.DonationAmount)}
                />
              ))}
            </div>
          </Fragment>
        );
      })}
    </DonationHistoryList>
  )
}


const QR2GSchoolsDonationHistory = ({ isDonationHistoryLoading, donationHistoryList, MainAccount }) => {
  if (isDonationHistoryLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }
  if (donationHistoryList.length == 0) {
    return (
      <DonationHistoryList>
        <DonationHistoryListItem>
          <DonationHistoryColumn header left>
            Date
          </DonationHistoryColumn>
          <DonationHistoryColumn header flex={1}>
            Non-Profit
          </DonationHistoryColumn>
          <DonationHistoryColumn header right>
            Amount
          </DonationHistoryColumn>
        </DonationHistoryListItem>
        <DonationHistoryListItem key={1}>
          {' '}
          <DonationHistoryColumn left />
          {/* {donation.SponsorAccount.AccountName} */}
          <DonationHistoryColumn flex={2} header>
            No Donations Yet
          </DonationHistoryColumn>
          <DonationHistoryColumn right />
        </DonationHistoryListItem>{' '}
      </DonationHistoryList >
    )
  }

  return (
    <DonationHistoryList>
      <div
        style={{
          borderBottom: `2px solid #ddd`,
          alignItems: 'center',
          textAlign: 'left',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <h4>Provided by:</h4>
        <img
          src={MainAccount.AccountLogo}
          alt={MainAccount.AccountName}
          key={MainAccount.AccountID}
          style={{
            height: '5rem',
            alignSelf: 'center',
            margin: '10px',
          }}
        />
      </div>
      <DonationHistoryListItem>
        <DonationHistoryColumn header left>
          Date
        </DonationHistoryColumn>
        <DonationHistoryColumn header flex={1}>
          School
        </DonationHistoryColumn>
        <DonationHistoryColumn header right>
          Amount
        </DonationHistoryColumn>
      </DonationHistoryListItem>

      {donationHistoryList.map((donation) => {
        return (
          <Fragment key={donationHistoryList.indexOf(donation)}>
            <DonationHistoryListItem key={donation.DateRedeemed}>
              {' '}
              <DonationHistoryColumn left>
                {parseDotNetDateMonthDay(donation.DateRedeemed)}
              </DonationHistoryColumn>
              {/* {donation.SponsorAccount.AccountName} */}
              <DonationHistoryColumn flex={2} header>
                {donation.LastCause.Name}
              </DonationHistoryColumn>
              <DonationHistoryColumn right>
                {formatMoney(donation.TotalDonations)}
              </DonationHistoryColumn>
            </DonationHistoryListItem>{' '}

          </Fragment>
        );
      })}
    </DonationHistoryList>
  )
}

function DonationHistory() {
  const UserUUID = useAuth()?.user?.UUID;
  const { appID } = useAuth()
  const [donationHistoryList, setDonationHistoryList] = useState([]);
  const [isDonationHistoryLoading, setDonationHistoryLoading] = useState(true);
  const [res, setRes] = useState({})

  //console.log("in donation historys")
  const navigate = useHistory();
  //console.log("navigate", navigate)
  useEffect(() => {
    let isCurrent = true;

    if (UserUUID) {
      getqR2gDonationHistory({ UserUUID, AppID: appID }).then((resp) => {
        setRes(resp)
        //console.log('DONATION HISTORY', resp);
        if (isCurrent) {

          if (appID == 4) {
            setDonationHistoryList(resp.DonationList);
          } else {
            setDonationHistoryList(resp.DonationListNew);
          }
          setDonationHistoryLoading(false);
        }
      });
    }

    return () => {
      isCurrent = false;
    };
  }, [UserUUID]);

  return (
    <Panel>
      {/* <button onClick={() => <Redirect to="/" />}>Back</button>
      <img src={Banner} className={css`
      height: 150px;
      width: 100%
    `} /> */}
      {appID == 4 ? <QR2GSchoolsDonationHistory donationHistoryList={donationHistoryList} isDonationHistoryLoading={isDonationHistoryLoading} MainAccount={res.MainAccount} />
        : <Rv2GDonationHistory donationHistoryList={donationHistoryList} isDonationHistoryLoading={isDonationHistoryLoading} />}
    </Panel>
  );
}

export default DonationHistory;
